import React from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import StoryblokService from "../utils/storyblok-service";
import { PageContext } from "src/utils/context";

export default class Editor extends React.Component {
  state = {
    story: null,
    cta_referrer: null
  };

  async getInitialStory() {
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get("path");
    let {
      data: { story },
    } = await StoryblokService.get(`cdn/stories/${path}`, { version: "draft" });
    return story;
  }

  async componentDidMount() {
    let story = await this.getInitialStory();
    if (story.content) this.setState({ story });
    setTimeout(() => StoryblokService.initEditor(this), 200);
  }

  setCtaReferrer = (value) => {
    this.setState({ cta_referrer: value })
  }

  render() {
    if (this.state.story === null) {
      return <div>Loading</div>;
    }
    return (
      <PageContext.Provider
        value={{
          button_override_title: this.state.story.content.button_override_title,
          button_override_url: this.state.story.content.button_override_url,
          name: this.state.story.name,
          setCtaReferrer: this.setCtaReferrer,
          ctaReferrer: this.state.cta_referrer  
        }}
      >
        <Layout location={this.props.location} isEditor={true} postInfo={this.state.story}>
          <Page
            blok={{
              ...this.state.story.content,
              breadcrumbs: this.props.pageContext.breadcrumb,
              first_published_at: this.state.story?.first_published_at,
              name: this.state.story?.name,
            }}
          />
        </Layout>
      </PageContext.Provider>
    );
  }
}
